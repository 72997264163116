.container {
    border: 1px solid darkgrey;
    border-radius: 0.5em;
    margin: 0.5em;
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 5;
    background-color: white;
    zoom: 80%;
    position: relative;
}

.containerHidden {
    display: none;
}

.containerEmpty {
    border: 1px solid darkgrey;
    border-radius: 0.5em;
    margin: 0.5em;
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 5;
    background-color: white;
    zoom: 80%;
    position: relative;
    width: 50px;
}

.showButton {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.hideButton {
    position: absolute;
    right: 0;
}

.container > div {
    margin-bottom: 10px;
}

.container > div:last-child {
    margin-bottom: 0;
}

.markerSettings {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.markerSettings * {
    margin-right: 0.5em;
}

.markerSettings *:last-child {
    margin-right: 0;
}

.markerSettings p {
    margin-top: 0;
    margin-bottom: 0;
    flex-grow: 1;
}

.markerSettings button {
    width: 2em;
    height: 2em;
}
